import React from "react";

const PaidInvoicesTable = ({ paidInvoices }) => {
  return (
    <div style={{ marginTop: "20px", color: "black" }}>
      <h2 style={{ color: "#333", fontSize: "1.5em", marginBottom: "10px" }}>Paid Invoices</h2>
      <table style={{ borderCollapse: "collapse", borderSpacing: "0", borderRadius: "8px", overflow: "hidden", boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)" }}>
        <thead>
          <tr>
            <th style={{ padding: "15px", textAlign: "left", backgroundColor: "#f2f2f2", color: "#333", borderBottom: "2px solid #ddd" }}>Invoice Number</th>
            <th style={{ padding: "15px", textAlign: "left", backgroundColor: "#f2f2f2", color: "#333", borderBottom: "2px solid #ddd" }}>Amount Paid</th>
            <th style={{ padding: "15px", textAlign: "left", backgroundColor: "#f2f2f2", color: "#333", borderBottom: "2px solid #ddd" }}>Status</th>
            <th style={{ padding: "15px", textAlign: "left", backgroundColor: "#f2f2f2", color: "#333", borderBottom: "2px solid #ddd" }}>Payment Date</th>
            <th style={{ padding: "15px", textAlign: "left", backgroundColor: "#f2f2f2", color: "#333", borderBottom: "2px solid #ddd" }}>Invoice (pdf)</th>
          </tr>
        </thead>
        <tbody>
          {paidInvoices.map((invoice) => (
            <tr key={invoice.id} style={{ backgroundColor: "#f9f9f9", borderBottom: "1px solid #ddd" }}>
              <td style={{ padding: "15px", textAlign: "left" }}>{invoice.number}</td>
              <td style={{ padding: "15px", textAlign: "left" }}>${(invoice.amount_paid / 100).toFixed(2)}</td>
              <td style={{ padding: "15px", textAlign: "left", color: "green" }}>{invoice.status}</td>
              <td style={{ padding: "15px", textAlign: "left" }}>{new Date(invoice.created * 1000).toLocaleDateString()}</td>
              <td style={{ padding: "15px", textAlign: "left" }}>
                <a href={invoice.invoice_pdf} download rel="noopener noreferrer" style={{ color: "#007bff", textDecoration: "none" }}>Download Invoice</a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PaidInvoicesTable;
