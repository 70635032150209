const routes = {
  home: "/",
  notFound: "/404",
  about: "/about",
  pricing: "/pricing",
  contact: "/contact-us",
  termsConditions: "/fair-use-policy",
  privacyPolicy: '/privacy-policy',

  auth: {
    logIn: "/login",
    signUp: "/sign-up",
    resetPassword: "/reset-password",
    forgotPassword: "/forgot-password",
    emailVerification: "/email-verification",
    emailVerified: "/verify-email",
  },

  panel: {
    dashboard: "/dashboard",
    wizard: "/wizard",
    fancyTitles: "/wizard/fancy-titles",
    titleOutline: "/wizard/title-outline",
    wizardEditor: "/wizard/editor",
    documents: "/documents",
    setting: "/settings",
    viewArticle: "/documents/article-in-view",
    pricing: "/pricing",
    guides: "/guides",
    zapierCampaigns: "/zapier-campaigns",
    bulkGeneration: "/bulk-generation",
    apiDocumentation: "/api-documentation",
    campaignsHistory: "/campaign-history",
    addTeamMember: '/add-team-member',
    viewTeamMembers: '/add-team-member/view-members',

    zapier : {
      zapier: '/zapier',
      zapierGuides: '/zapier/guides',
    },

    createCampaigns: "/bulk-generation/create-campaigns",
    selectedCampaign: "/bulk-generation/campagin",
    selectedMonthlyBasic: "/guides/selected-monthly-basic",
    selectedMonthlyPro: "/guides/selected-monthly-pro",
    selectedYearlyBasic: "/guides/selected-yearly-basic",
    selectedYearlyPro: "/guides/selected-yearly-pro",
    campaignArticleToView: "/articleToView",
    previewCampaignArticle: "/bulk-generation/view-article",

    pricingPlans: {
      basicMonthly: '/pricing/basic-monthly',
      proMonthly: '/pricing/pro-monthly',
      teamMonthly: '/pricing/team-monthly',
      enterpriseMonthly: '/pricing/exterprise-monthly',
      basicYearly: '/pricing/basic-yearly',
      proYearly: '/pricing/pro-yearly',
      teamYearly: '/pricing/team-yearly',
      enterpriseYearly: '/pricing/exterprise-yearly',
      lifeTimePlan: '/pricing/life-time-deal',
    }
  },
};

export default routes;
