import React, { useEffect, useState } from "react";
import { FaGoogle } from "react-icons/fa";
import { Link, Redirect } from "react-router-dom";
import logo from "../../../assets/images/logo.png";
import "./login.scss";
import useForm from "./useForm";
import validate from "./validation";
import { TextField } from "../../Common/TextField";
import routes from "../../../config/routes";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, loginUserWithGoogle } from "../../../redux/actions/auth";
import { DotLoader } from "react-spinners";
import { toast } from "react-toastify";
import loginImages from "../../../assets/images/loginImages.jpg";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.Authentication?.user);

  const token = useSelector((state) => state?.Authentication?.token);

  const authLoader = useSelector((state) => state?.Authentication?.authLoader);

  useEffect(() => {
    //why is this being used?
    if (!user && !token) {
      localStorage.clear();
    }
  }, []);

  const { handleChange, values, getErrors, errors } = useForm(validate);

  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      console.log(codeResponse);
      try {
        const { data } = await axios.get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${codeResponse.access_token}`,
              Accept: "application/json",
            },
          }
        );
        const payload = {
          email: data.email,
          name: data.name,
          isSSO: true,
          password: null,
        };

        dispatch(loginUserWithGoogle(payload));
      } catch (error) {
        console.log(error);
      }
    },
    onError: (error) => {
      toast.error("Failed to login with google.");
      console.log({ error });
    },
  });

  const handleLogin = (e) => {
    e.preventDefault();

    getErrors(e);
    const validateErrors = validate(values);
    let isValid = true;
    Object.keys(validateErrors).forEach((key) => {
      if (validateErrors[key]) {
        isValid = false;
      }
    });

    if (isValid) {
      let payload = {
        email: values.email,
        password: values.password,
      };

      dispatch(loginUser(payload));
    }
  };

  let goForward;

  if (user) {
    goForward =
      JSON.parse(user)?.isEmailVerified === true ||
      JSON.parse(user)?.isSSO === true;
  }

  if (
    user &&
    token &&
    JSON.parse(user)?.isEmailVerified === false &&
    JSON.parse(user)?.isSSO === false
  ) {
    return <Redirect to={routes.auth.emailVerification} />;
  }

  if (user && token && goForward) {
    return <Redirect to={routes.panel.dashboard} />;
  }

  return (
    <>
      <section id="login">
        <div className="row" style={{ marginLeft: "0" }}>
          <div className="col-md-4 px-0">
            <div className="inner">
              <div className="logo_blk">
                <img src={logo} alt="logo" />
              </div>

              <h1>Nice to see you again!</h1>

              <form className="signup_form">
                <label>Email</label>
                <div className="input_blk">
                  <TextField
                    name="email"
                    value={values?.email}
                    onChange={handleChange}
                    placeholder="e.g xyz@gmail.com"
                  />
                  {errors?.email && (
                    <p className="form-error-message">{errors?.email}</p>
                  )}
                </div>

                <label>Password</label>
                <div className="input_blk mb-2">
                  <TextField
                    type={passwordVisibility ? "text" : "password"}
                    name="password"
                    value={values?.password}
                    onChange={handleChange}
                    placeholder="*********"
                  />
                  <i
                    className={`passwordVisibilityReset fa ${
                      passwordVisibility
                        ? "fa-eye icon_blk"
                        : "fa-eye-slash icon_blk"
                    }`}
                    onClick={() => setPasswordVisibility(!passwordVisibility)}
                  />
                  {errors?.password && (
                    <p className="form-error-message">{errors?.password}</p>
                  )}
                </div>

                <div className="forgot_blk">
                  <Link to={routes.auth.forgotPassword} className="forgot">
                    Forgot Password?
                  </Link>
                </div>

                {authLoader ? (
                  <div className="loaderWrapper">
                    <DotLoader
                      className="login-clip-loader"
                      color={"#7F56D9"}
                    />
                    <h1
                      style={{
                        color: "#7F56D9",
                        fontSize: "2.4rem",
                      }}
                    >
                      &nbsp;&nbsp;&nbsp;&nbsp;Logging you in...
                    </h1>
                  </div>
                ) : (
                  <button
                    type="button"
                    className="signup_btn"
                    onClick={handleLogin}
                  >
                    Login
                  </button>
                )}
                <hr />

                <button
                  type="button"
                  style={{
                    display: "flex",
                    width: "100%",
                    margin: "1rem auto",
                    padding: "1rem 2rem",
                    gap: "1em",
                    border: "none",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "5px",
                    backgroundColor: "black",
                    color: "white",
                    fontFamily: "DM Sans, sans-serif",
                    fontSize: "16px",
                    cursor: "pointer",
                    transition: "background-color 0.2s ease-in-out",
                  }}
                  onClick={login}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 48 48">
<path fill="#fbc02d" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12	s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20	s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"></path><path fill="#e53935" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039	l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"></path><path fill="#4caf50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36	c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"></path><path fill="#1565c0" d="M43.611,20.083L43.595,20L42,20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571	c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"></path>
</svg>
                  Sign in with Google
                </button>
                <p className="login_acc">
                  Don't have account?&nbsp;
                  <Link to={routes.auth.signUp} className="blue_text">
                    Sign up
                  </Link>
                </p>
              </form>
            </div>
          </div>
          <div className="col-md-8 px-0">
            <div className="img_blk">
              <img src={loginImages} alt="" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default App;
