import React from "react";
import Copy from "../../../assets/images/Copy.svg";
import "./apiDocumentation.scss";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Link } from "react-router-dom/cjs/react-router-dom";
import routes from "../../../config/routes";
const ApiDocumentation = () => {

    const BASE_URL = 'https://api.trolly.ai';

    const token = useSelector((state) => state?.Authentication?.token);

    const user =
        useSelector((state) => state?.Authentication?.user) ||
        localStorage.getItem("User");

    const userId = JSON.parse(user)?._id;

    const handleCopy = (name, value) => {
        navigator.clipboard
            .writeText(value)
            .then(() => {
                toast.success(`${name} Copied!`);
            })
            .catch((error) => {
                toast.error(`Didn't copy. Try Again.`);
            });
    };

    return (
        <>
            <section id="apiDocumentation">
                <h2>API Documentation</h2>
                <div className="inner">
                    <h5>Security Note,</h5>
                    <p>
                        Your secret Token ID & User ID are listed below. Do not share your
                        API key with others, or expose it in the browser or other
                        client-side code, In order to protect the security of your account.
                    </p>
                    <div className="private_key">
                        <h6>Your Private Keys</h6>
                        <div className="key_blk">
                            <label>Token Id:</label>
                            <input type="text" value={`${token?.slice(0, 65)}...`} disabled />
                            <div className="icon_blk" onClick={() => handleCopy('Token', token)}>
                                <img src={Copy} alt="copy" />
                            </div>
                        </div>
                        <div className="key_blk">
                            <label>User Id:</label>
                            <input type="text" style={{ marginLeft: "2.7rem" }} value={userId} disabled />
                            <div className="icon_blk" onClick={() => handleCopy('User ID', userId)}>
                                <img src={Copy} alt="copy" />
                            </div>
                        </div>
                    </div>
                    <div className="api_restriction_blk">
                        <div className="top">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M20 12C20 14.1217 19.1571 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20C9.87827 20 7.84344 19.1571 6.34315 17.6569C4.84285 16.1566 4 14.1217 4 12C4 9.87827 4.84285 7.84344 6.34315 6.34315C7.84344 4.84285 9.87827 4 12 4C14.1217 4 16.1566 4.84285 17.6569 6.34315C19.1571 7.84344 20 9.87827 20 12ZM13 16C13 16.2652 12.8946 16.5196 12.7071 16.7071C12.5196 16.8946 12.2652 17 12 17C11.7348 17 11.4804 16.8946 11.2929 16.7071C11.1054 16.5196 11 16.2652 11 16C11 15.7348 11.1054 15.4804 11.2929 15.2929C11.4804 15.1054 11.7348 15 12 15C12.2652 15 12.5196 15.1054 12.7071 15.2929C12.8946 15.4804 13 15.7348 13 16ZM12 7C11.7348 7 11.4804 7.10536 11.2929 7.29289C11.1054 7.48043 11 7.73478 11 8V12C11 12.2652 11.1054 12.5196 11.2929 12.7071C11.4804 12.8946 11.7348 13 12 13C12.2652 13 12.5196 12.8946 12.7071 12.7071C12.8946 12.5196 13 12.2652 13 12V8C13 7.73478 12.8946 7.48043 12.7071 7.29289C12.5196 7.10536 12.2652 7 12 7Z"
                                    fill="#B0BABF"
                                />
                            </svg>
                            *Fair Usage<Link to={routes.termsConditions} style={{color: 'black'}}>Policy</Link>apply.
                        </div>
                    </div>

                    <h4>Generate Fancy Headline</h4>
                    <div className="title_blk">
                        {BASE_URL}/open-ai/generateFancyHeadline
                    </div>
                    <div className="code_blk">
                        <p className="top_blk">Code snippet</p>
                        <div className="code">
                            <b>Payload: </b>
                            <br />
                            title = 'Cars'
                            <br />
                            language = 'English'
                            <br />
                            <b>Header: </b>
                            <br />
                            Bearer Token Id
                            {/* <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    d="M5.58579 5.58579C5.96086 5.21071 6.46957 5 7 5H13C13.5304 5 14.0391 5.21071 14.4142 5.58579C14.7893 5.96086 15 6.46957 15 7H7V15C6.46957 15 5.96086 14.7893 5.58579 14.4142C5.21071 14.0391 5 13.5304 5 13V7C5 6.46957 5.21071 5.96086 5.58579 5.58579Z"
                                    fill="#84919A"
                                />
                                <path
                                    d="M9.58579 9.58579C9.21071 9.96086 9 10.4696 9 11V17C9 17.5304 9.21071 18.0391 9.58579 18.4142C9.96086 18.7893 10.4696 19 11 19H17C17.5304 19 18.0391 18.7893 18.4142 18.4142C18.7893 18.0391 19 17.5304 19 17V11C19 10.4696 18.7893 9.96086 18.4142 9.58579C18.0391 9.21071 17.5304 9 17 9H11C10.4696 9 9.96086 9.21071 9.58579 9.58579Z"
                                    fill="#84919A"
                                />
                            </svg> */}
                        </div>
                    </div>
                    <h4>Generate Title Outlines</h4>
                    <div className="title_blk">
                        {BASE_URL}/open-ai/generateQuestions/{`{title}`}/{`{keywords}`}/{`{language}`}
                    </div>
                    <div className="code_blk">
                        <p className="top_blk">Code snippet</p>
                        <div className="code">
                            <b>Path Params: </b>
                            <br />
                            title = 'Cars'
                            <br />
                            language = 'English'
                            <br />
                            keywords = {`['new', 'old', 'horse power']`}
                            <br />
                            <b>Header: </b>
                            <br />
                            Bearer Token Id
                        </div>
                    </div>
                    <h4>Generate Short Article</h4>
                    <div className="title_blk">
                        {BASE_URL}/open-ai/generateArticle
                    </div>
                    <div className="code_blk">
                        <p className="top_blk">Code snippet</p>
                        <div className="code">
                            <b>Request Body: </b>
                            <br />
                            outline = List of outlines generated using (Generate Title Outlines) api call.
                            <br />
                            headline = 'All you need to know about cars'
                            <br />
                            topic = 'Cars'
                            <br />
                            keywords = {`['new', 'old', 'horse power']`}
                            <br />
                            userId = User Id
                            <br />
                            language = 'English'
                            <br />
                            <b>Header: </b>
                            <br />
                            Bearer Token Id
                        </div>
                    </div>
                    <h4>Generate Long Article</h4>
                    <div className="title_blk">
                        {BASE_URL}/open-ai/generateLongArticle
                    </div>
                    <div className="code_blk">
                        <p className="top_blk">Code snippet</p>
                        <div className="code">
                            <b>Request Body: </b>
                            <br />
                            outline = List of outlines generated using (Generate Title Outlines) api call.
                            <br />
                            headline = 'All you need to know about cars'
                            <br />
                            topic = 'Cars'
                            <br />
                            keywords = {`['new', 'old', 'horse power']`}
                            <br />
                            userId = User Id
                            <br />
                            articleType = 'long'
                            <br />
                            language = 'English'
                            <br />
                            <b>Header: </b>
                            <br />
                            Bearer Token Id
                        </div>
                    </div>
                    <h4>Generate Long Pro Article</h4>
                    <div className="title_blk">
                        {BASE_URL}/open-ai/generateLongArticle
                    </div>
                    <div className="code_blk">
                        <p className="top_blk">Code snippet</p>
                        <div className="code">
                            <b>Request Body: </b>
                            <br />
                            outline = List of outlines generated using (Generate Title Outlines) api call.
                            <br />
                            headline = 'All you need to know about cars'
                            <br />
                            topic = 'Cars'
                            <br />
                            keywords = {`['new', 'old', 'horse power']`}
                            <br />
                            userId = User Id
                            <br />
                            articleType = 'long_pro'
                            <br />
                            language = 'English'
                            <br />
                            <b>Header: </b>
                            <br />
                            Bearer Token Id
                        </div>
                    </div>
                    <h4>Generate Article Summary</h4>
                    <div className="title_blk">
                        {BASE_URL}/open-ai/generateLongArticle
                    </div>
                    <div className="code_blk">
                        <p className="top_blk">Code snippet</p>
                        <div className="code">
                            <b>Request Body: </b>
                            <br />
                            outline = List of outlines generated using (Generate Title Outlines) api call.
                            <br />
                            headline = 'All you need to know about cars'
                            <br />
                            language = 'English'
                            <br />
                            <b>Header: </b>
                            <br />
                            Bearer Token Id
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ApiDocumentation;
