import React, { useEffect } from "react";
import './emailVerification.scss';
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import routes from "../../../config/routes";
import { verifyUserEmail } from "../../../redux/actions/auth";
import { DotLoader } from "react-spinners";

const EmailVerified = (props) => {

    const dispatch = useDispatch();

    const accessToken = new URLSearchParams(window?.location?.search).get('token');
    console.log(accessToken)

    const user = useSelector((state) => state?.Authentication?.user);
    const token = useSelector((state) => state?.Authentication?.token);
    const authLoader = useSelector((state) => state?.Authentication?.authLoader);


    useEffect(() => {
        if (accessToken) {
            dispatch(verifyUserEmail(accessToken, ''))
        }
    }, [dispatch, accessToken])

    if (!accessToken) {
        return <Redirect to={routes.home} />
    }

    if(user?.isEmailVerified === true) {
        return <Redirect to={routes.home}/>
    }

    if (user && token) {
        return <Redirect to={routes.panel.dashboard} />
    }

    return (
        <>
            {
                authLoader ?
                    <div className="loaderWrapper">
                        <DotLoader className="login-clip-loader" color={"#7F56D9"} />
                        <h1
                            style={{
                                color: "#7F56D9",
                                fontSize: "2.4rem",
                            }}
                        >
                            &nbsp;&nbsp;&nbsp;&nbsp;Wait. Verifying your email...
                        </h1>
                    </div>
                    :
                    <div className="coming-soon">
                        <h1 style={{ color: '#7F56D9', textAlign: 'center' }}>
                            Sorry for the inconvenience.
                            <br />
                            Kindly refresh the page. And report it to trolly.ai.
                        </h1>
                    </div>
            }

        </>
    )
}

export default EmailVerified;
