import React from 'react';

const BillingInformation = ({ paymentMethod }) => {
  return (
    <div style={{border: "1px solid #ccc", borderRadius: "5px", padding: "10px", marginTop: "0.5em"}}>
      <div style={{fontWeight: "bold", fontSize: "1.2em"}}>Billing Information</div>
      <div style={{marginTop: "10px"}}>
        <div style={{marginBottom: "10px"}}>
          <div style={{fontWeight: "bold"}}>Payment Method</div>
          <button style={{marginLeft: "1em", padding: "5px 10px", borderRadius: "3px", backgroundColor: "#64c1f6", color: "#fff", border: "none", cursor: "pointer"}}>Update</button>
        </div>
        <div>
          <span style={{fontWeight: "bold", marginRight: "0.5em"}}>Card Information: </span>
          <span>
            {paymentMethod?.card
              ? `${paymentMethod.card.brand} ending in ${paymentMethod.card.last4}`
              : 'No card information available'}
          </span>
        </div>
      </div>
    </div>
  );
};

export default BillingInformation;
