import React from "react";
import "./pricingDetails.scss";
const PricingDetails = () => {
  return (
    <>
      <section id="pricing_details">
        <div className="site_container">
          <h2 className="title">Compare Plan</h2>
          <div className="table_outer">
            <table className="plans_table">
              <thead>
                <tr>
                  <th>
                    <td>
                      <h3>What's included</h3>
                    </td>
                  </th>
                  <th>
                    <td>
                      <h3>Basic</h3>
                    </td>
                  </th>
                  <th>
                    <td>
                      <h3>Pro</h3>
                    </td>
                  </th>
                  <th>
                    <td>
                      <h3>Team</h3>
                    </td>
                  </th>
                  <th>
                    <td>
                      <h3>Enterprise</h3>
                    </td>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Credits</td>
                  <td>
                    100{/* <i class="fa-sharp fa-solid fa-circle-check"></i> */}
                  </td>
                  <td>300</td>
                  <td>1000</td>
                  <td>Unlimited</td>
                </tr>
                <tr>
                  <td>Text Editor</td>
                  <td>
                    <i class="fa-sharp fa-solid fa-circle-check"></i>
                  </td>
                  <td>
                    <i class="fa-sharp fa-solid fa-circle-check"></i>
                  </td>
                  <td>
                    <i class="fa-sharp fa-solid fa-circle-check"></i>
                  </td>
                  <td>
                    <i class="fa-sharp fa-solid fa-circle-check"></i>
                  </td>
                </tr>
                <tr>
                  <td> Auto SEO Optimization</td>
                  <td>
                    <i class="fa-sharp fa-solid fa-circle-check"></i>
                  </td>
                  <td>
                    <i class="fa-sharp fa-solid fa-circle-check"></i>
                  </td>
                  <td>
                    <i class="fa-sharp fa-solid fa-circle-check"></i>
                  </td>
                  <td>
                    <i class="fa-sharp fa-solid fa-circle-check"></i>
                  </td>
                </tr>

                <tr>
                  <td>10+ Output styles</td>
                  <td>
                    <i class="fa-sharp fa-solid fa-circle-check"></i>
                  </td>
                  <td>
                    <i class="fa-sharp fa-solid fa-circle-check"></i>
                  </td>
                  <td>
                    <i class="fa-sharp fa-solid fa-circle-check"></i>
                  </td>
                  <td>
                    <i class="fa-sharp fa-solid fa-circle-check"></i>
                  </td>
                </tr>
                <tr>
                  <td>20+ Languages</td>
                  <td>
                    <i class="fa-sharp fa-solid fa-circle-check"></i>
                  </td>
                  <td>
                    <i class="fa-sharp fa-solid fa-circle-check"></i>
                  </td>
                  <td>
                    <i class="fa-sharp fa-solid fa-circle-check"></i>
                  </td>
                  <td>
                    <i class="fa-sharp fa-solid fa-circle-check"></i>
                  </td>
                </tr>

                <tr>
                  <td>Grammar Checker</td>
                  <td>
                    <i class="fa-sharp fa-solid fa-circle-check"></i>
                  </td>
                  <td>
                    <i class="fa-sharp fa-solid fa-circle-check"></i>
                  </td>
                  <td>
                    <i class="fa-sharp fa-solid fa-circle-check"></i>
                  </td>
                  <td>
                    <i class="fa-sharp fa-solid fa-circle-check"></i>
                  </td>
                </tr>
                <tr>
                  <td> In-Article Images & Videos</td>
                  <td>
                    <i class="fa-sharp fa-solid fa-circle-check"></i>
                  </td>
                  <td>
                    <i class="fa-sharp fa-solid fa-circle-check"></i>
                  </td>
                  <td>
                    <i class="fa-sharp fa-solid fa-circle-check"></i>
                  </td>
                  <td>
                    <i class="fa-sharp fa-solid fa-circle-check"></i>
                  </td>
                </tr>

                <tr>
                  <td>Bulk Article Generator</td>
                  <td>
                    <i class="fa-sharp fa-solid fa-circle-check"></i>
                  </td>
                  <td>
                    <i class="fa-sharp fa-solid fa-circle-check"></i>
                  </td>
                  <td>
                    <i class="fa-sharp fa-solid fa-circle-check"></i>
                  </td>
                  <td>
                    <i class="fa-sharp fa-solid fa-circle-check"></i>
                  </td>
                </tr>
                <tr>
                  <td>API Access</td>
                  <td>-</td>
                  <td>
                    <i class="fa-sharp fa-solid fa-circle-check"></i>
                  </td>
                  <td>
                    <i class="fa-sharp fa-solid fa-circle-check"></i>
                  </td>
                  <td>
                    <i class="fa-sharp fa-solid fa-circle-check"></i>
                  </td>
                </tr>
                <tr>
                  <td>Zapier Automation</td>
                  <td>
                    <i class="fa-sharp fa-solid fa-circle-check"></i>
                  </td>
                  <td>
                    <i class="fa-sharp fa-solid fa-circle-check"></i>
                  </td>
                  <td>
                    <i class="fa-sharp fa-solid fa-circle-check"></i>
                  </td>
                  <td>
                    <i class="fa-sharp fa-solid fa-circle-check"></i>
                  </td>
                </tr>
                <tr>
                  <td>Wordpress Direct Automation</td>
                  <td>-</td>
                  <td>-</td>
                  <td>
                    {/* <i class="fa-sharp fa-solid fa-circle-check"></i> */}-
                  </td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Custom Styles</td>
                  <td>-</td>
                  <td>-</td>
                  <td>
                    -{/* <i class="fa-sharp fa-solid fa-circle-check"></i> */}
                  </td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Add members </td>
                  <td>-</td>
                  <td>1</td>
                  <td>5</td>
                  <td>10</td>
                </tr>
                <tr>
                  <td>SERP Benchmarks</td>

                  <td>-</td>
                  <td>
                    -{/* <i class="fa-sharp fa-solid fa-circle-check"></i> */}
                  </td>
                  <td>
                    -{/* <i class="fa-sharp fa-solid fa-circle-check"></i> */}
                  </td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>SEO Analytics</td>
                  <td>-</td>
                  <td>
                    -{/* <i class="fa-sharp fa-solid fa-circle-check"></i> */}
                  </td>
                  <td>
                    -{/* <i class="fa-sharp fa-solid fa-circle-check"></i> */}
                  </td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Team Access</td>
                  <td>-</td>
                  <td>-</td>
                  <td>
                    -{/* <i class="fa-sharp fa-solid fa-circle-check"></i> */}
                  </td>
                  <td>-</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
};

export default PricingDetails;
