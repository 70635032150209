import React, { useCallback, useState } from "react";
import "../WizardPage/WizardPage.scss";
import "./titleOutline.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  generateArticle,
  generateLongArticle,
  generateTitleOutline,
} from "../../../redux/actions/panel";
import { Redirect } from "react-router";
import routes from "../../../config/routes";
import { DotLoader } from "react-spinners";
import { toast } from "react-toastify";
import progress2 from "../../../assets/images/progress2.jpg";
import OutlineInput from "./OutlineInput";

const TitleOutline = () => {
  const dispatch = useDispatch();
  const [isUserProvidingOutline, setIsUserProvidingOutline] = useState(false);
  const token = useSelector((state) => state?.Authentication?.token);
  const [userOutline, setUserOutline] = useState("");

  const [redirect, setRedirect] = useState(false);
  let keyWords = JSON.parse(localStorage.getItem("Keywords"));
  let selectedTitle = localStorage.getItem("Selected-Fancy-Title");
  let title = localStorage.getItem("Title");
  const language = localStorage.getItem("Language");

  const articleLoader = useSelector((state) => state?.PanelData?.articleLoader);

  const outlineData = useSelector((state) => state?.PanelData?.titleOutline);
  const [editedOutline, setEditedOutline] = useState(
    outlineData && outlineData[0].outline
  );
  const [selectedOutline, setSelectedOutline] = useState(
    outlineData && outlineData[0].outline
  );
  const panelLoader = useSelector((state) => state?.PanelData?.panelLoader);

  const user = useSelector((state) => state?.Authentication?.user);
  const { _id: userId, role } = JSON.parse(user);

  const handleSelectedOutline = (e, value) => {
    setSelectedOutline(value.outline);
    setEditedOutline(value.outline);
  };

  const handleGenerateArticle = (e) => {
    e.preventDefault();

    if (selectedOutline === "" && userOutline === "") {
      toast.error("Please select/create an outline to generate an article.");
      return;
    }

    const stringKeywords = keyWords?.join(",");
    localStorage?.setItem("Selected-Outline", selectedOutline || userOutline);
    setRedirect(true);

    const parentUserId =
      role === "Team Member" ? JSON.parse(user)?.parentUserId : null;

    const payload = {
      outline: isUserProvidingOutline ? userOutline : editedOutline,
      headline: selectedTitle,
      topic: title,
      keywords: stringKeywords,
      userId: role === "Team Member" ? parentUserId : userId,
      language: language,
      articleType: "short",
    };

    const articleLengthToParse = localStorage.getItem("article-length");
    const articleLength = articleLengthToParse
      ? JSON.parse(articleLengthToParse)
      : articleLengthToParse;
    if (articleLength === "long" || articleLength === "longPro") {
      payload.articleType = articleLength;
      dispatch(generateLongArticle(payload));
    } else {
      dispatch(generateArticle(payload));
    }
  };
  const handleGenerateAnotherOutline = (e) => {
    setRedirect(false);
    e.preventDefault();

    let stringKeywords = keyWords?.join(",");
    const payload = {
      topic: selectedTitle,
      keywords: stringKeywords,
      language: language,
    };
    dispatch(generateTitleOutline(payload));
  };

  const itemData = useCallback(
    (item) => {
      return item?.replace(/^\d+\.\s/, "");
    },
    [outlineData]
  );
  const handleUserGenerateOutline = () => {
    setIsUserProvidingOutline((prev) => !prev);
  };
  if (redirect && articleLoader) {
    return <Redirect to={routes.panel.wizardEditor} />;
  }

  if (outlineData?.length === 0) {
    return <Redirect to={routes.panel.fancyTitles} />;
  }

  if (user === undefined || token === undefined) {
    return <Redirect to={routes.auth.logIn} />;
  }

  const whenChosenOutline = () => {
    return panelLoader ? (
      <div className="loaderWrapper">
        <DotLoader className="login-clip-loader" color={"#7F56D9"} />
        <h1
          style={{
            color: "#7F56D9",
            fontSize: "2.4rem",
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;
          {redirect
            ? "Creating your article. Please wait a little..."
            : "Generating another outline..."}
        </h1>
      </div>
    ) : (
      <div className="btns_blk">
        <button
          type="button"
          className="re_generate_title_btn"
          onClick={handleGenerateAnotherOutline}
        >
          Generate another outline
        </button>
        <button
          type="button"
          className="generate_title_btn"
          onClick={handleGenerateArticle}
          style={{ color: "white" }}
        >
          Generate Article
        </button>
      </div>
    );
  };

  const whenCreatedOutline = () => {
    return panelLoader ? (
      <div className="loaderWrapper">
        <DotLoader className="login-clip-loader" color={"#7F56D9"} />
        <h1
          style={{
            color: "#7F56D9",
            fontSize: "2.4rem",
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;
          {redirect
            ? "Creating your article. Please wait a little..."
            : "Generating another outline..."}
        </h1>
      </div>
    ) : (
      <div className="btns_blk">
        <button
          type="button"
          className="generate_title_btn"
          onClick={handleGenerateArticle}
          style={{ color: "white" }}
        >
          Generate Article
        </button>
      </div>
    );
  };
  return (
    <>
      <section id="WizardPage">
        <h2>Wizard</h2>

        <div className="inner">
          <h3>Generate SEO Optimized Article</h3>
          <p>Please fill the form below to receive a well optimized article</p>

          {isUserProvidingOutline ? (
            <OutlineInput
              userOutline={userOutline}
              setUserOutline={setUserOutline}
              handleUserGenerateOutline={handleUserGenerateOutline}
            />
          ) : (
            <>
              <form className="headline_form">
                <div className="progress_blk">
                  <img src={progress2} alt="progress 2" />
                </div>
                <hr />
                <div className="flex-between">
                  <label className="mb-4">Select / Edit Outline</label>
                  <button
                    type="button"
                    className="btn_outline"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#6941c6",
                      padding: "0.5em",
                      outline: "none",
                      border: "none",
                      marginBottom: "0.5em",
                      color: "white",
                      borderRadius: "0.5em",
                    }}
                    onClick={handleUserGenerateOutline}
                  >
                    Create an outline
                  </button>
                </div>
                {/* {outlineData?.map((item, ind) => {
                  return (
                    <>
                      <div
                        key={item}
                        className={`outline_head ${ind > 0 ? "mt-4" : ""}`}
                      >
                        Outline {ind + 1}
                        <input
                          type="radio"
                          name="selected"
                          id={item}
                          checked={selectedOutline === item.outline}
                          onChange={(e) => handleSelectedOutline(e, item)}
                        />
                      </div>
                      <div key={ind} className="outline_box">
                        						
                        <ul className="outline_list">
                          {item.outline.split("\n").map((items, index) => {
                            return (
                              <li key={index}>
                                <span className="count">
                                  {index + 1}.&nbsp;
                                </span>
                                <p>{itemData(items)}</p>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </>
                  );
                })} */}
                {outlineData?.map((item, ind) => {
                  return (
                    <>
                      <div
                        key={item}
                        className={`outline_head ${ind > 0 ? "mt-4" : ""}`}
                      >
                        Outline {ind + 1}
                        <input
                          type="radio"
                          name="selected"
                          id={item}
                          checked={selectedOutline === item.outline}
                          onChange={(e) => handleSelectedOutline(e, item)}
                        />
                      </div>
                      <div key={ind} className="outline_box">
                        {selectedOutline === item.outline ? (
                          <textarea
                            value={editedOutline}
                            rows={15}
                            cols={65}
                            onChange={(e) => setEditedOutline(e.target.value)}
                            className="outline_textarea"
                          />
                        ) : (
                          <ul className="outline_list">
                            {item.outline.split("\n").map((items, index) => {
                              return (
                                <li key={index}>
                                  <span className="count">
                                    {index + 1}.&nbsp;
                                  </span>
                                  <p>{itemData(items)}</p>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </div>
                    </>
                  );
                })}
              </form>
            </>
          )}

          {isUserProvidingOutline ? whenCreatedOutline() : whenChosenOutline()}
        </div>
      </section>
    </>
  );
};

export default TitleOutline;
