const bulkTypes = {
    SET_ALL_CAMPAIGNS: 'SET_ALL_CAMPAIGNS',
    SET_CAMPAIGN_LOADER: 'SET_CAMPAIGN_LOADER',
    SET_CAMPAIGN_REDIRECTION: 'SET_CAMPAIGN_REDIRECTION',
    SET_CREATED_CAMPAIGN_REDIRECTION: 'SET_CREATED_CAMPAIGN_REDIRECTION',
    SET_CAMPAIGN_BY_ID: 'SET_CAMPAIGN_BY_ID',
    SET_CAMPAGIN_COUNT_LOADER: "SET_CAMPAGIN_COUNT_LOADER",
    SET_CAMPAIGN_COUNT: 'SET_CAMPAIGN_COUNT',
};

export default bulkTypes;
