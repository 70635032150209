import React, { useEffect, useState } from "react";
import campaignImage from "../../../assets/images/campaignImage.jpg";
import "./bulkGeneration.scss";
import { useDispatch, useSelector } from "react-redux";
import { campaginCount, getAllCampaigns, getCampaignById, setCampaignRedirection } from "../../../redux/actions/bulkGeneration";
import { Link, Redirect } from "react-router-dom";
import routes from "../../../config/routes";
import { ClimbingBoxLoader, DotLoader } from "react-spinners";
import moment from "moment";
import { toast } from "react-toastify";

const BulkGenerationHome = () => {

    const [redirect, setRedirect] = useState(false);

    const dispatch = useDispatch();
    const user = useSelector((state) => state?.Authentication?.user) 
    const userId = JSON.parse(user)?._id;
    const role = JSON.parse(user)?.role;
    useEffect(() => {
        dispatch(setCampaignRedirection(false));
        if (role === 'Team Member') {
            const parentUserId = JSON.parse(user)?.parentUserId;
            dispatch(getAllCampaigns(parentUserId))
            dispatch(campaginCount(parentUserId))
        }
        if (role !== 'Team Member') {
            dispatch(getAllCampaigns(userId))
            dispatch(campaginCount(userId))
        }
    }, [])

    const campaignCountLoader = useSelector((state) => state?.BulkGeneration?.campaginCountLoader);
    const campaignCountData = useSelector((state) => state?.BulkGeneration?.campaignCountInfo);
    const campaignLoader = useSelector((state) => state?.BulkGeneration?.campaignLoader)
    const allCampaigns = useSelector((state) => state?.BulkGeneration?.allCampaigns);
    const campaignRedirection = useSelector((state) => state?.BulkGeneration?.campaignRedirection);
    const createdCampaignRedirection = useSelector((state) => state?.BulkGeneration?.createdCampaignRedirection);
    const handleSelectedCampaign = (value) => {
        dispatch(getCampaignById(value?._id));
        setRedirect(true);
    }

    if (redirect && campaignRedirection) {
        return <Redirect to={routes.panel.selectedCampaign} />
    }

    return (
        <>
            <section id="BulkGeneration">
                <h2>My Campaigns</h2>
                <div className="campaigns_result">
                    {
                        campaignCountLoader ?
                            <div className="loader-campaign-count">
                                <DotLoader color='#7F56D9' size={40}/>
                            </div>
                            :
                            <>
                                <div className="campaigns_card">
                                    <div className="img_blk">
                                        <img src={campaignImage} alt="" />
                                    </div>
                                    <div className="right_sec">
                                        <span>Total Campaigns</span>
                                        <h5>{campaignCountData?.totalCampaignCount}</h5>
                                        
                                    </div>
                                </div>
                                <div className="campaigns_card">
                                    <div className="img_blk">
                                        <img src={campaignImage} alt="" />
                                    </div>
                                    <div className="right_sec">
                                        <span>Manual Campaigns</span>
                                        <h5>{campaignCountData?.manualCampaignCount}</h5>
                                    
                                    </div>
                                </div>
                                <div className="campaigns_card border_non">
                                    <div className="img_blk">
                                        <img src={campaignImage} alt="" />
                                    </div>
                                    <div className="right_sec">
                                        <span>Automatic Campaigns</span>
                                        <h5>{campaignCountData?.automaticCampaignCount}</h5>
                                       
                                    </div>
                                </div>
                            </>
                    }
                </div>

                <div className="all_campaigns">
                    {
                        createdCampaignRedirection ?
                            <button type="button" className="campaign_btn" onClick={() => toast.info('Creating a campaign.')}>
                                <DotLoader size={20} color="#fff" />
                            </button>
                            :
                            <Link to={routes.panel.createCampaigns}>
                                <button type="button" className="campaign_btn">
                                    <i className="fa fa-solid fa-plus" />
                                    New Campaign
                                </button>
                            </Link>
                    }
                    <div className="head">
                        <h3>All Campaigns</h3>
                    </div>
                    <div className="campaigns_table">
                        <table>
                            <thead>
                                <tr>
                                    <td style={{ width: "17.78rem" }}>Campaign Name</td>
                                    <td style={{ width: "13.78rem" }}>Mode</td>
                                    <td style={{ width: "15.8rem" }}>Date</td>
                                    <td style={{ width: "13.78rem" }}>Number of Articles</td>
                                  
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    campaignLoader ?
                                        <tr className="table-body-no-content">
                                            <td colSpan="5">
                                                <div className="content-wrapper col-lg-12 col-md-12 col-sm-12 col-12">
                                                    <DotLoader color="#7F56D9" />
                                                    <h3 style={{ color: "#7F56D9", marginBottom: '0px' }}>Fetching Data...</h3>
                                                </div>
                                            </td>
                                        </tr>
                                        :
                                        <>
                                            {
                                                allCampaigns?.length === 0 ?
                                                    <>
                                                        <tr className="table-body-no-content">
                                                            <td colSpan="5">
                                                                <div className="content-wrapper col-lg-12 col-md-12 col-sm-12 col-12">
                                                                    <ClimbingBoxLoader color="#7F56D9" />
                                                                    <h3 style={{ color: "#7F56D9", marginBottom: '0px' }}>Sorry No Data Available</h3>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </>
                                                    :
                                                    allCampaigns?.map((item) => {
                                                        return (
                                                            <tr key={item.createdAt} onClick={() => handleSelectedCampaign(item)}>
                                                                <td>{item?.name}</td>
                                                                <td>{item?.publishingPreference?.preference}</td>
                                                                <td>{moment(item?.createdAt).format("LL")}</td>
                                                                <td>{item?.noOfArticles}</td>
                                                            </tr>
                                                        )
                                                    })
                                            }
                                        </>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </>
    );
};

export default BulkGenerationHome;
