import React, { useState } from "react";
import { Avatar } from "primereact/avatar";
import { AvatarGroup } from "primereact/avatargroup";

import img2 from "../../../assets/profiles/2.jpg";
import img3 from "../../../assets/profiles/3.jpg";
import img4 from "../../../assets/profiles/4.jpg";
import img5 from "../../../assets/profiles/5.jpg";
import img6 from "../../../assets/profiles/1.1.jpg";
import generatingText from "../../../assets/images/generatingText.svg";
import edit from "../../../assets/images/edit.svg";

import { DotLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { emailCapture } from "../../../redux/actions/home";

const HeroSection = ({ referHome, referAbout }) => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const loader = useSelector((state) => state?.Authentication?.authLoader);

  const handleEmailCapture = () => {
    if (!email) {
      toast.error("Please enter your email address.");
    } else if (
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      toast.error("Enter a valid email!");
    } else {
      const payload = {
        email
      };
      dispatch(emailCapture(payload));
      setEmail("");
    }
  };

  return (
    <section id="hero_sec">
      <div className="site_container" ref={referHome}>
        <div className="inner">
          <h2 className="main_head">
            Create professional SEO articles, 2x faster.
          </h2>
          <p className="desc">
            Unleash the power of AI to write faster, dream bigger, and boost
            your creativity. It's like magic!
          </p>

          <AvatarGroup className="mt-5">
            <Avatar image={img3} size="xlarge" shape="circle" />
            <Avatar image={img4} size="xlarge" shape="circle" />
            <Avatar image={img2} size="xlarge" shape="circle" />
            <Avatar image={img5} size="xlarge" shape="circle" />
            <Avatar image={img6} size="xlarge" shape="circle" />
            <Avatar
              a
              label="3000+"
              shape="circle"
              size="xlarge"
              style={{
                backgroundColor: "#7F56D9",
                color: "#ffffff",
                fontSize: "13px",
              }}
            />
          </AvatarGroup>
          <p className="avatar_text">
            More than 3500+ users are having more fun
          </p>
          <div className="input_blk">
            <input
              type="email"
              placeholder="Your best email address"
              value={email}
              onChange={(e) => setEmail(e?.target?.value)}
            />
            <div className="get_demo_btn icon_blk">
              <button
                onClick={handleEmailCapture}
                type="button"
                className="btn_with_loader"
                style={{ color: "white", background: "none", border: "none" }}
              >
                {loader ? (
                  <DotLoader size={20} color={"#ffff"} />
                ) : (
                  "Start Trial"
                )}
              </button>
            </div>
          </div>
          <ul className="feature_list">
            <li>Match your content</li>
            <li>Perfectly a side hustle</li>
            <li>Undetectable AI content</li>
          </ul>
        </div>
        <div className="row blog_writing">
          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="article_card">
              <div className="card_inner">
                <div className="icon_blk">
                  <img src={generatingText} alt="" />
                </div>
                <div className="text_blk">
                  <h4>Text Editor</h4>
                  <p>Save hundreds of hours</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="article_card pink">
              <div className="card_inner">
                <div className="icon_blk">
                  <img src={edit} alt="" />
                </div>
                <div className="text_blk">
                  <h4>Articles Writing</h4>
                  <p ref={referAbout}>Save hundreds of hours</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
