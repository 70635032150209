import React, { useEffect, useState } from "react";

import { Link, Redirect } from "react-router-dom";
import logo from "../../../assets/images/logo.png";
import "./login.scss";
import useForm from "./useForm";
import validate from "./validation";
import { TextField } from "../../Common/TextField";
import routes from "../../../config/routes";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, loginUserWithGoogle } from "../../../redux/actions/auth";
// import GoogleLogin from "react-google-login";
import { DotLoader } from "react-spinners";
import { gapi } from "gapi-script";
import { toast } from "react-toastify";
import loginImages from "../../../assets/images/loginImages.jpg";
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import axios from "axios";

const Login = () => {
	// const clientId =
	// 	"651676208501-gfismbgiboie4p9p8i6m4nkrg11cboo4.apps.googleusercontent.com";

	// useEffect(() => {
	// 	const start = () => {
	// 		gapi.client.init({
	// 			clientId: clientId,
	// 			scope: "profile email",
	// 		});
	// 	};

	// 	gapi.load("client:auth2", start);
	// });

	const dispatch = useDispatch();
	const user = useSelector((state) => state?.Authentication?.user);

	const token = useSelector((state) => state?.Authentication?.token);

	const authLoader = useSelector((state) => state?.Authentication?.authLoader);

	useEffect(() => { //why is this being used? 
		if (!user && !token) {
			localStorage.clear();
		}
	}, []);

	const { handleChange, values, getErrors, errors } = useForm(validate);

	const [passwordVisibility, setPasswordVisibility] = useState(false);
	const login = useGoogleLogin({
        onSuccess: (codeResponse) => {
            console.log(codeResponse)
        },
        onError: (error) => console.log('Login Failed:', error)
    });

	// const handleGoogleSuccess = (response) => {
	// 	if (response) {
	// 		console.log(response)
	// 		const payload = {
	// 			email: response?.wt?.cu,
	// 			name: response?.wt?.Ad,
	// 			isSSO: true,
	// 			password: null,
	// 		};
	// 		dispatch(loginUserWithGoogle(payload));
	// 	}
	// };

	// const handleGoogleFailure = (err) => {
	// 	toast.error("Failed to login with google.");
	// 	console.log({ err });
	// };

	const handleLogin = (e) => {
		e.preventDefault();

		getErrors(e);
		const validateErrors = validate(values);
		let isValid = true;
		Object.keys(validateErrors).forEach((key) => {
			if (validateErrors[key]) {
				isValid = false;
			}
		});

		if (isValid) {
		let payload = {
			email: values.email,
			password: values.password,
		};

		dispatch(loginUser(payload));
		}
	};

	let goForward;

	if (user) {
		goForward =
			JSON.parse(user)?.isEmailVerified === true ||
			JSON.parse(user)?.isSSO === true;
	}

	if (
		user &&
		token &&
		JSON.parse(user)?.isEmailVerified === false &&
		JSON.parse(user)?.isSSO === false
	) {
		return <Redirect to={routes.auth.emailVerification} />;
	}

	if (user && token && goForward) {
		return <Redirect to={routes.panel.dashboard} />;
	}

	

	return (
		<>
			<section id="login">
				<div className="row" style={{ marginLeft: "0" }}>
					<div className="col-md-4 px-0">
						<div className="inner">
							<div className="logo_blk">
								<img src={logo} alt="logo" />
							</div>

							<h1>Nice to see you again!</h1>

							<form className="signup_form">
								<label>Email</label>
								<div className="input_blk">
									<TextField
										name="email"
										value={values?.email}
										onChange={handleChange}
										placeholder="e.g xyz@gmail.com"
									/>
									{errors?.email && (
										<p className="form-error-message">{errors?.email}</p>
									)}
								</div>

								<label>Password</label>
								<div className="input_blk mb-2">
									<TextField
										type={passwordVisibility ? "text" : "password"}
										name="password"
										value={values?.password}
										onChange={handleChange}
										placeholder="*********"
									/>
									<i
										className={`passwordVisibilityReset fa ${
											passwordVisibility
												? "fa-eye icon_blk"
												: "fa-eye-slash icon_blk"
										}`}
										onClick={() => setPasswordVisibility(!passwordVisibility)}
									/>
									{errors?.password && (
										<p className="form-error-message">{errors?.password}</p>
									)}
								</div>

								<div className="forgot_blk">

									<Link to={routes.auth.forgotPassword} className="forgot">
										Forgot Password?
									</Link>
								</div>

								{authLoader ? (
									<div className="loaderWrapper">
										<DotLoader
											className="login-clip-loader"
											color={"#7F56D9"}
										/>
										<h1
											style={{
												color: "#7F56D9",
												fontSize: "2.4rem",
											}}
										>
											&nbsp;&nbsp;&nbsp;&nbsp;Logging you in...
										</h1>
									</div>
								) : (
									<button
										type="button"
										className="signup_btn"
										onClick={handleLogin}
									>
										Login
									</button>
								)}
								<hr />
								{/* <GoogleLogin
									className="googleLogin-Package"
									scope="profile email"
									clientId={clientId}
									buttonText="Continue With Google"
									onSuccess={handleGoogleSuccess}
									onFailure={handleGoogleFailure}
									cookiePolicy={"single_host_origin"}
									accessType="online"									
								/> */}
                <button onClick={login}>Sign in with Google 🚀 </button>

								<p className="login_acc">
									Don't have account?&nbsp;
									<Link to={routes.auth.signUp} className="blue_text">
										Sign up
									</Link>
								</p>
							</form>
						</div>
					</div>
					<div className="col-md-8 px-0">
						<div className="img_blk">
							<img src={loginImages} alt="" />
						</div>
					</div>
				</div>
			</section>

		</>
	);
};

export default Login;