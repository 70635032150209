import React from "react";
import logo from "../../assets/images/logo.png";
import twitter from "../../assets/images/twitter.svg";

import "./footer.scss";
const Footer = () => {
  return (
    <>
      <footer id="footer">
        <div className="site_container">
          <div className="footer_top">
            <div className="logo_sec">
              <div className="logo_blk">
                <img src={logo} alt="" />
              </div>
              <p className="slogan">
                What matters is productivity with fun culture
              </p>
            </div>
            <ul className="social_blk">
              
              <li>
                <a
                  href="http://www.twitter.com/octalabsxyz"
                  className="icon_blk"
                  target="_blank noreferrer"
                >
                  <img src={twitter} alt="facebook" />
                </a>
              </li>{" "}
              
            </ul>
          </div>
          <div className="footer_bottom">
            <ul className="links">
              <li>
                {/* <a href="">About</a>  */}
                <p>About</p>
              </li>
              <li>
                {/* <a href="">Company</a> */}
                <p>Company</p>
              </li>{" "}
              <li>
                {/* <a href="">Press</a> */}
                <p>Press</p>
              </li>
            </ul>
            <p className="rights">All rights reserved.</p>
          </div>
        </div>
      </footer>
     
    </>
  );
};
export default Footer;
