const validate = async (values) => {
    let errors = {};

    if (!values.username) {
        errors.username = "Username is required."
    }
    else if (/^(?=.*[0-9])/i.test(values.username) || /^(?=.*[!@#$%^&*])/i.test(values.username)) {
        errors.username = "Username can not have numeric and special characters."
    }

    if (!values.email) {
        errors.email = "Email is required.";
    } else if (
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(values.email)
    ) {
        errors.email = "Invalid email address.";
    } else{
        // Perform email validation using the API endpoint
        try {
            const response = await fetch(`${process.env.REACT_APP_HOST}/auth/disposable`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: values.email }),
            });
            const data = await response.json();
            if (data.disposable) {
                errors.email = "Email address is disposable";
            }
        } catch (error) {
            console.error('Error validating email:', error);
            // Handle error if the API call fails
            errors.email = "Error validating email.";
        }
    }

    if (!values.password) {
        errors.password = "Enter your password.";
    }
    else if (values.password.length < 8 || !/[A-Z]/.test(values.password) || !/[0-9]/.test(values.password)) {
        errors.password = "Password must be at least 8 characters with 1 upper case letter and 1 number.";
    }

    if (!values.confirmPassword) {
        errors.confirmPassword = "Enter confirm password.";
    }

    else if (values.confirmPassword !== values.password) {
        errors.confirmPassword = "Passwords do not match.";
    }

    return errors;
};

export default validate;
