// const Host = "http://34.230.19.58:3000";
// const Host = "https://api.trolly.ai";
// const Host = 'https://c468-113-203-198-61.ngrok-free.app'
//const Host = "http://localhost:3000";
// const Host = "https://api-prod.trolly.ai";
// const Host = 'https://api-dev.trolly.ai';
// const Host = "http://localhost:5000";


const Host = process.env.REACT_APP_HOST;


// const Host = "http://95.217.218.227";

const api = {
	home: {
		emailCapture: `${Host}/users/emailCapturing`,
	},

	auth: {
		login: `${Host}/auth/signin`,
		register: `${Host}/auth/signup`,
		registerWithRefferal: (referralCode) =>
			`${Host}/auth/signup?referralCode=${referralCode}`,
		loginWithGoogle: `${Host}/users/createGoogleUser`,
		forgotPassword: `${Host}/auth/forgot-password`,
		resetPassword: `${Host}/auth/reset-password`,
		verifyEmail: (token) => `${Host}/auth/verifyEmail/${token}`,
	},

	panel: {
		generateFancyTitles: `${Host}/open-ai/generateFancyHeadline`,
		generateTitleOutlines: `${Host}/open-ai/generateQuestions`,
		generateArticle: `${Host}/open-ai/generateArticle`,
		generateLongArticle: `${Host}/open-ai/generateLongArticle`,

		saveArticle: `${Host}/user-history/saveData`,
		saveArticleForUser: `${Host}/user-history/saveUserHistory`,

		saveEditedArticle: (id) => `${Host}/user-history/updateData/${id}`,
		getArticles: (userId) => `${Host}/user-history/getUserHistory/${userId}`,

		getArticleById: (id) => `${Host}/user-history/getDataById/${id}`,
		articleSummary: `${Host}/open-ai/generateArticleSummary`,

		getSeoScore: `${Host}/open-ai/getSeoScore`,
	},

	pricing: {
		createSubscription: `${Host}/stripe/createSubscription`,
		expiryStatus: (userId) =>
			`${Host}/stripe/getSubscriptionExpiryStatus/${userId}`,
		cancelSubscription: (userId) =>
			`${Host}/stripe/cancelSubscription/${userId}`,
		renewSubscription: `${Host}/stripe/renew-subscription`,
		lifeTimePlan: `${Host}/stripe/createLifeTimeDeal`,
	},

	user: {
		getUserDetail: (userId) => `${Host}/users/${userId}`,
		updateUserPassword: `${Host}/auth/update-password`,
		updateUser: (userId) => `${Host}/users/${userId}`,
		updateName: (userId) => `${Host}/users/updateName/${userId}`,
	},

	bulkGeneration: {
		getAllCampaigns: (userId) => `${Host}/campaign/${userId}`,
		postCampaign: `${Host}/campaign`,
		getCampaignById: (campaignId) => `${Host}/campaign/id/${campaignId}`,
		campaginCount: (userId) => `${Host}/campaign/count/${userId}`,
	},

	zapier: {
		pushToZapier: (articleId) =>
			`${Host}/zapier/manual/button/press/${articleId}`,
	},

	addTeamMember: {
		addMember: (email, username) =>
			`${Host}/users/addTeamMember/${email}/${username}`,
		viewTeamMembers: `${Host}/users/getTeamMembersByParentUserId`,
		deleteTeamMember: (teamMemberId) =>
			`${Host}/users/deleteTeamMember/${teamMemberId}`,
	},
};

export default api;
