import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import createRootReducer from './reducer';

export const history = createBrowserHistory();

export const doCreateStore = () => {
    const sagaMiddleware = createSagaMiddleware();
    const middleware = [
        thunk,
        sagaMiddleware,
        routerMiddleware(history)
    ];



    const store = createStore(
        createRootReducer(history),
        applyMiddleware(...middleware)
    );

    return store;
};
