import React, { useEffect, useRef, useState } from "react";
import panelLogo from "../../assets/images/panelLogoo.jpg";

import { SidebarItems } from "../../constants/SidebarItem";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { userExpiryDetails } from "../../redux/actions/pricing";
import { getUserDetails, logout } from "../../redux/actions/auth";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import "./panelSidebar.scss";
import routes from "../../config/routes";

import addTeamMember from "../../assets/images/new-doc-icon.svg";



const PanelSideBar = ({ isActive, isClicked }) => {
  const dispatch = useDispatch();

  const textRef = useRef(null);

  const [modalShow, setModalShow] = useState(false);

  const [viewRefferalLink, setViewRefferalLink] = useState(false);
  const user = useSelector((state) => state?.Authentication?.user);
  const { email, name, _id: userId, referralCode, role } = JSON.parse(user);

  useEffect(() => {
    if (role === "Team Member") {
      const parentUserId = JSON.parse(user)?.parentUserId;
      dispatch(userExpiryDetails(parentUserId));
      dispatch(getUserDetails(userId, ""));
    }
    if (role !== "Team Member") {
      dispatch(userExpiryDetails(userId));
      dispatch(getUserDetails(userId, ""));
    }
  }, [dispatch, role, user, userId]);

  let referralLink = `https://trolly.ai/sign-up?referralCode=${referralCode}`;

  const expiryDetail = useSelector((state) => state?.Pricing?.expiryDetail);

  let noOfArticles = expiryDetail?.subscription?.noOfArticles;

  const handleCopy = () => {
    navigator.clipboard
      .writeText(referralLink)
      .then(() => {
        toast.success("Referral Code Copied!");
        setViewRefferalLink(false);
      })
      .catch((error) => {
        setViewRefferalLink(false);
        toast.error(`Didn't copy. Try Again.`);
      });
  };

  let lifeTimePlan = !!(
    expiryDetail?.subscription?.subscriptionPlan === "LifeTime Deal"
  );

  const teamSideBar = SidebarItems.slice(0, 4).concat(SidebarItems.slice(6));

  const noApiItem = [...SidebarItems.slice(0, 5), ...SidebarItems.slice(6)];

  const showApiDocs = !!(
    expiryDetail?.subscription?.subscriptionPlan === "Free Trial" ||
    expiryDetail?.subscription?.subscriptionPlan === "Basic Monthly" ||
    expiryDetail?.subscription?.subscriptionPlan === "Basic Yearly"
  );

  const teamMemberShow =
    expiryDetail?.subscription?.subscriptionPlan?.includes("Pro") ||
    expiryDetail?.subscription?.subscriptionPlan?.includes("Enterprise") ||
    expiryDetail?.subscription?.subscriptionPlan?.includes("Team") ||
    expiryDetail?.subscription?.subscriptionPlan === "trollyai_tier1" ||
    expiryDetail?.subscription?.subscriptionPlan === "trollyai_tier2";
  return (
    <>
      <section id="sidebar" className={isActive ? "active" : ""}>
        <div className="inner">
          <div className="top_sec">
            <div className="logo_blk">
              <img src={panelLogo} alt="" onClick={isClicked} />
            </div>
            <ul className="menu_list">
              {role === "Team Member" &&
                teamSideBar?.map((item) => (
                  <Link
                    to={item.route}
                    key={item.route}
                    style={{
                      textDecoration: "none",
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    <li
                      className={
                        window.location.pathname.includes(item.route)
                          ? "active"
                          : ""
                      }
                      id={item.id}
                      onClick={isClicked}
                    >
                      <div className="icon_blk">
                        <img src={item.img} alt="" />
                      </div>
                      {/* <Link to={item.route}>{item.name}</Link> */}
                      <p>{item.name}</p>
                    </li>
                  </Link>
                ))}
              {role === "Root User" &&
                (showApiDocs
                  ? noApiItem?.map((item) => (
                      <Link
                        to={item.route}
                        key={item.route}
                        style={{
                          textDecoration: "none",
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        <li
                          className={
                            window.location.pathname.includes(item.route)
                              ? "active"
                              : ""
                          }
                          id={item.id}
                          onClick={isClicked}
                        >
                          <div className="icon_blk">
                            <img src={item.img} alt="" />
                          </div>
                          {/* <Link to={item.route}>{item.name}</Link> */}
                          <p>{item.name}</p>
                        </li>
                      </Link>
                    ))
                  : SidebarItems?.map((item) => (
                      <Link
                        to={item.route}
                        key={item.route}
                        style={{
                          textDecoration: "none",
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        <li
                          className={
                            window.location.pathname.includes(item.route)
                              ? "active"
                              : ""
                          }
                          id={item.id}
                          onClick={isClicked}
                        >
                          <div className="icon_blk">
                            <img src={item.img} alt="" />
                          </div>
                          {/* <Link to={item.route}>{item.name}</Link> */}
                          <p>{item.name}</p>
                        </li>
                      </Link>
                    )))}
              {teamMemberShow && role !== "Team Member" && (
                <Link
                  to={routes.panel.addTeamMember}
                  style={{
                    textDecoration: "none",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  <li
                    className={
                      window.location.pathname.includes("add-team-member")
                        ? "active"
                        : ""
                    }
                    onClick={isClicked}
                  >
                    <div className="icon_blk">
                      <img src={addTeamMember} alt="" />
                    </div>
                    {/* <Link to={routes.panel.addTeamMember}>Add Team Member</Link> */}
                    <p>Add Team Member</p>
                  </li>
                </Link>
              )}
            </ul>
          </div>
          <div className="bottom_sec">
            <div className="refer_blk">
              {lifeTimePlan ? (
                <h6 style={{ color: "#7F56D9" }}>Unlimited credits.</h6>
              ) : (
                <>
                  {expiryDetail?.subscription?.subscriptionPlan ===
                    "Free Trial" && (
                    <>
                      <h4>Credits Remaining: {noOfArticles}/5</h4>
                      <div className="progress_blk">
                        <progress
                          value={((noOfArticles / 5) * 100).toFixed(2)}
                          max={100}
                          className="side-progress"
                        />
                        <span>{`${((noOfArticles / 5) * 100).toFixed(
                          2
                        )}%`}</span>
                      </div>
                    </>
                  )}
                  {expiryDetail?.subscription?.subscriptionPlan ===
                    "Basic Monthly" && (
                    <>
                      <h4>Credits Remaining: {noOfArticles}/100</h4>
                      <div className="progress_blk">
                        <progress
                          value={((noOfArticles / 100) * 100).toFixed(2)}
                          max={100}
                        />
                        <span>{`${((noOfArticles / 100) * 100).toFixed(
                          2
                        )}%`}</span>
                      </div>
                    </>
                  )}
                  {expiryDetail?.subscription?.subscriptionPlan ===
                    "Pro Monthly" && (
                    <>
                      <h4>Credits Remaining: {noOfArticles}/300</h4>
                      <div className="progress_blk">
                        <progress
                          value={((noOfArticles / 300) * 100).toFixed(2)}
                          max={100}
                        />
                        <span>{`${((noOfArticles / 300) * 100).toFixed(
                          2
                        )}%`}</span>
                      </div>
                    </>
                  )}
                  {expiryDetail?.subscription?.subscriptionPlan ===
                    "Basic Yearly" && (
                    <>
                      <h4>Credits Remaining: {noOfArticles}/100</h4>
                      <div className="progress_blk">
                        <progress
                          value={((noOfArticles / 100) * 100).toFixed(2)}
                          max={100}
                        />
                        <span>{`${((noOfArticles / 100) * 100).toFixed(
                          2
                        )}%`}</span>
                      </div>
                    </>
                  )}
                  {expiryDetail?.subscription?.subscriptionPlan ===
                    "Pro Yearly" && (
                    <>
                      <h4>Credits Remaining: {noOfArticles}/300</h4>
                      <div className="progress_blk">
                        <progress
                          value={((noOfArticles / 300) * 100).toFixed(2)}
                          max={100}
                        />
                        <span>{`${((noOfArticles / 300) * 100).toFixed(
                          2
                        )}%`}</span>
                      </div>
                    </>
                  )}
                  {expiryDetail?.subscription?.subscriptionPlan ===
                    "Team Monthly" && (
                    <>
                      <h4>Credits Remaining: {noOfArticles}/1000</h4>
                      <div className="progress_blk">
                        <progress
                          value={((noOfArticles / 1000) * 100).toFixed(2)}
                          max={100}
                        />
                        <span>{`${((noOfArticles / 1000) * 100).toFixed(
                          2
                        )}%`}</span>
                      </div>
                    </>
                  )}
                  {expiryDetail?.subscription?.subscriptionPlan ===
                    "Team Yearly" && (
                    <>
                      <h4>Credits Remaining: {noOfArticles}/1000</h4>
                      <div className="progress_blk">
                        <progress
                          value={((noOfArticles / 1000) * 100).toFixed(2)}
                          max={100}
                        />
                        <span>{`${((noOfArticles / 1000) * 100).toFixed(
                          2
                        )}%`}</span>
                      </div>
                    </>
                  )}
                  {expiryDetail?.subscription?.subscriptionPlan ===
                    "Enterprise Monthly" && (
                    <h6 style={{ color: "#7F56D9" }}>
                      Unlimited credits for a month.
                    </h6>
                  )}
                  {expiryDetail?.subscription?.subscriptionPlan ===
                    "Enterprise Yearly" && (
                    <h6 style={{ color: "#7F56D9" }}>
                      Unlimited credits for an year.
                    </h6>
                  )}
                  {expiryDetail?.subscription?.subscriptionPlan ===
                    "trollyai_tier1" && (
                    <>
                      <h4>Credits Remaining: {noOfArticles}/30</h4>
                      <div className="progress_blk">
                        <progress
                          value={((noOfArticles / 30) * 100).toFixed(2)}
                          max={100}
                        />
                        <span>{`${((noOfArticles / 30) * 100).toFixed(
                          2
                        )}%`}</span>
                      </div>
                    </>
                  )}
                  {expiryDetail?.subscription?.subscriptionPlan ===
                    "trollyai_tier2" && (
                    <h6 style={{ color: "#7F56D9" }}>
                      Unlimited credits for a month.
                    </h6>
                  )}
                </>
              )}
              <p>Earn 5 credits when you refer 5 friends</p>
              {viewRefferalLink ? (
                <>
                  <span style={{ maxWidth: "20px" }} ref={textRef}>
                    {referralLink.slice(8, 45)}....
                  </span>
                  <br />
                  <span className="refer_link" onClick={handleCopy}>
                    Copy
                  </span>
                </>
              ) : (
                <span
                  className="refer_link"
                  onClick={() => setViewRefferalLink(true)}
                >
                  Refer a Friend
                </span>
              )}
            </div>
            <hr />
            <div className="logout_blk">
              <div className="profile_blk">
                <div className="profile_img">
                  <span>{name?.charAt(0)}</span>
                </div>
                <div className="profile_name">
                  <h5>{name}</h5>
                  <p>{email.slice(0, 20)}..</p>
                </div>
              </div>
              <button className="logout_btn" onClick={() => setModalShow(true)}>
                <i className="fa-solid fa-arrow-right-from-bracket"></i>
              </button>
            </div>
          </div>
        </div>
      </section>


      <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <h4
            style={{
              marginTop: "10px",
              color: "black",
              fontWeight: "600",
              textAlign: "center",
            }}
            className="mb-4"
          >
            Do you want to logout?
          </h4>
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            className="modal-yes-button"
            onClick={() => dispatch(logout())}
          >
            Yes
          </Button>
          <Button
            className="modal-no-button"
            onClick={() => setModalShow(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PanelSideBar;
