import React from "react";
import "./titleOutline.scss";
const OutlineInput = ({
  userOutline,
  setUserOutline,
  handleUserGenerateOutline,
}) => {
  return (
    <div>
      <div className="flex-between">
        <p
          style={{
            fontSize: "1.5rem",
            fontWeight: "bold",
          }}
        >
          Create Your Own Outline (numbered)
        </p>

        <button
          onClick={handleUserGenerateOutline}
          style={{
            fontWeight: "bold",
            backgroundColor: "#6941c6",
            padding: "0.5em",
            outline: "none",
            border: "none",
            marginBottom: "0.5em",
            color: "white",
            borderRadius: "0.5em",
          }}
        >
          {" "}
          Select Generated Outline{" "}
        </button>
      </div>
      <textarea
        rows={25}
        cols={55}
        value={userOutline}
        onChange={(e) => setUserOutline(e.target.value)}
        style={{
          padding: "0.5em",
          border: "1px solid #6941C6",
        }}
        placeholder="Enter your outline here..."
      />
    </div>
  );
};

export default OutlineInput;
