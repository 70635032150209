import React from "react";
import { Link } from "react-router-dom";
import * as FileSaver from "file-saver";
import { ContentState, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertFromHTML } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateToHTML } from "draft-js-export-html";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { saveEditedArticle } from "../../../../redux/actions/panel";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import routes from "../../../../config/routes";
import "../../ArticleEditor/articleEditor.scss";
import { toast } from "react-toastify";
import axios from "axios";
import { DotLoader } from "react-spinners";
import Button from "react-bootstrap/Button";
import { FaRegCopy } from "react-icons/fa6";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { MdCropPortrait } from "react-icons/md";
import { MdCropLandscape } from "react-icons/md";
import ReactSpeedometer from "react-d3-speedometer/slim";

const ViewArticleFromHistory = () => {
  const dispatch = useDispatch();

  const [openAddImages, setOpenAddImages] = useState(false);
  const [imagesData, setImagesData] = useState(null);
  const [imageSearch, setImageSearch] = useState("technology");
  const [loaderForImages, setLoaderForImages] = useState(false);

  const [orientation, setOrientation] = useState("landscape");

  const token = useSelector((state) => state?.Authentication?.token);
  const user = useSelector((state) => state?.Authentication?.user);

  const expiryDetail = useSelector((state) => state?.Pricing?.expiryDetail);
  const plan = expiryDetail?.subscription?.subscriptionPlan;

  const withoutStripeHandler = (plan) =>
    ["trollyai_tier1", "trollyai_tier2", "LifeTime Deal"].includes(plan);

  const withoutStripe = withoutStripeHandler(plan);

  const [value, setValue] = useState(() => EditorState.createEmpty());

  const articleLoaded =
    useSelector((state) => state?.PanelData?.articleById) ||
    localStorage.getItem("Article-By-Id");

  let articleById;

  if (articleLoaded) {
    articleById = articleLoaded?._id
      ? articleLoaded
      : JSON.parse(articleLoaded);
  }

  const articleByIdLoader = useSelector(
    (state) => state?.PanelData?.articleByIdLoader
  );

  const contentState = value?.getCurrentContent();
  const contentHTML = stateToHTML(contentState);

  function htmlToPlainText(html) {
    var doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  }
  let contentText = htmlToPlainText(contentHTML);

  const accessKeyUnsplash = "rQdDZ-pCCOSDeBNKyurLxCryT2kgfPkvtTzWiORqNB4";

  const [articleTitle, setArticleTitle] = useState("");
  const [articleMetaDescription, setArticleMetaDescription] = useState("");
  const [saveData, setSaveData] = useState(false);

  const numberOfWords = contentText.split(/\s+/).length;
  const htmlTagsRegex = /<\s*([a-zA-Z0-9]+)(\s|>)/g;

  // Object to store tag counts
  const tagCounts = {};

  // Match HTML tags in the content and count them
  let match;
  while ((match = htmlTagsRegex.exec(contentHTML)) !== null) {
    const tagName = match[1].toLowerCase();
    tagCounts[tagName] = (tagCounts[tagName] || 0) + 1;
  }
  let numberOfHeadings = 0;
  for (const tag in tagCounts) {
    if (
      tag === "h1" ||
      tag === "h2" ||
      tag === "h3" ||
      tag === "h4" ||
      tag === "h5" ||
      tag === "h6"
    ) {
      numberOfHeadings += tagCounts[tag];
    }
  }
  const numberOfParagraphs = tagCounts.p;
  const numberOfImages = tagCounts.img;

  function countKeywords(text, keywords) {
    const keywordCount = {};

    // Convert text to lowercase for case-insensitive matching
    const lowerText = text.toLowerCase();
    // Iterate through each keyword
    keywords.forEach(({ keyword, lowestFrequency, highestFrequency }) => {
      // Create a regular expression to match the keyword, ignoring case
      const regex = new RegExp("\\b" + keyword.toLowerCase() + "\\b", "g");

      // Count occurrences of the keyword in the text
      const count = (lowerText.match(regex) || []).length;

      // Store the count for the keyword
      keywordCount[keyword] = count;
    });

    return keywordCount;
  }

  const keywordsOccurrence =
    articleById &&
    Array.isArray(articleById.extractedKeywords) &&
    countKeywords(contentText, articleById.extractedKeywords);

  useEffect(() => {
    if (openAddImages) {
      setLoaderForImages(true);
      axios
        .get(
          `https://api.unsplash.com/search/photos?page=1&per_page=30&query=${imageSearch}&client_id=${accessKeyUnsplash}&orientation=${orientation}`
        )
        .then((response) => {
          setImagesData(response?.data?.results);
          setLoaderForImages(false);
        })
        .catch((err) => {
          setLoaderForImages(false);
          console.log({ err });
        });
    }
  }, [openAddImages, orientation]);

  const handleCopyImageUrl = (url) => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        toast.success("Image Url Copied!");
      })
      .catch((error) => {
        toast.error(`Didn't copy. Try Again.`);
      });
  };

  const handleSearchImages = (e) => {
    e.preventDefault();

    if (!imageSearch) {
      toast.error("Please type something to search.");
    } else {
      setLoaderForImages(true);
      axios
        .get(
          `https://api.unsplash.com/search/photos?page=1&per_page=30&query=${imageSearch}&client_id=${accessKeyUnsplash}&orientation=${orientation}`
        )
        .then((response) => {
          setImagesData(response?.data?.results);
          setLoaderForImages(false);
        })
        .catch((err) => {
          setLoaderForImages(false);
          console.log({ err });
        });
    }
  };

  useEffect(() => {
    if (articleById && saveData) {
      const payload = {
        data: contentHTML,
        dataTitle: articleTitle,
        metaDescription: articleMetaDescription,
      };
      dispatch(saveEditedArticle(articleById?._id, payload));
    }
  }, [contentHTML, articleMetaDescription, articleTitle, saveData]);
  useEffect(() => {
    setValue(() =>
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(`${articleById?.data}`)
        )
      )
    );
    setArticleTitle(articleById?.dataTitle);
    setArticleMetaDescription(articleById?.metaDescription);
    setSaveData(true);

    // }
  }, [articleLoaded, articleById?._id]);

  if (user === undefined || token === undefined) {
    return <Redirect to={routes.auth.logIn} />;
  }

  const downloadFile = (content, fileType) => {
    let blob;
    let fileName = articleTitle;

    switch (fileType) {
      case "html":
        blob = new Blob([content], { type: "text/html" });
        fileName += ".html";
        break;
      case "txt":
        blob = new Blob([content], { type: "text/plain" });
        fileName += ".txt";
        break;
      default:
        break;
    }

    // For HTML (and potentially other types directly supported by Blob), proceed to create a download link.
    if (blob) {
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }
  };
  const handleDownload = () => {
    const blob = new Blob([contentHTML], { type: "text/plain;charset=utf-8" });
    FileSaver.saveAs(blob, `${articleTitle}.docx`);
  };

  function handleBackgroundColor(
    lowestFrequency,
    highestFrequency,
    actualFrequency
  ) {
    if (actualFrequency > highestFrequency) {
      return "#E58E73";
    }
    if (actualFrequency >= lowestFrequency) {
      return "#c6f6d5";
    } else {
      return "rgb(255, 245, 245)";
    }
  }

  function handleTextColor(lowestFrequency, highestFrequency, actualFrequency) {
    if (actualFrequency > highestFrequency) {
      return "#8B0000";
    }
    if (actualFrequency >= lowestFrequency) {
      return "#2b7a2b";
    } else {
      return "rgb(130, 39, 39)";
    }
  }

  return (
    <div className="row">
      <div className="add-image-button-wrapper">
        <button
          type="button"
          className="add_img"
          onClick={() => setOpenAddImages(true)}
        >
          Add Images
        </button>
      </div>
      {articleByIdLoader ? (
        <div className="col-md-12">
          <p style={{ color: "black" }}>Loader</p>
        </div>
      ) : articleById ? (
        <div
          className="relative"
          id="editor"
          style={{
            display: "grid",
            gridTemplateColumns: "3fr 1fr",
            width: "100%",
            gap: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "100%",
            }}
          >
            <div
              className="d-flex align-items-start pt-4"
              style={{
                position: "sticky",
                top: 0,
                background: "white",
              }}
            >
              <textarea
                className="article-textarea-input align-items-center justify-content-between"
                style={{ position: "sticky", top: 0 }}
                value={articleTitle}
                onChange={(e) => setArticleTitle(e.target.value)}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "6px",
                  marginTop: "8px",
                }}
              >
                <Button
                  variant="secondary"
                  size="lg"
                  style={{
                    borderRadius: "20px",
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                  onClick={() => {
                    // copy the whole content of the editor as markdown
                    navigator.clipboard.writeText(
                      `# ${articleTitle}\n\n${articleMetaDescription}\n\n${contentHTML}`
                    );
                    // navigator.clipboard.writeText(contentText);
                    toast.success("Article Copied!");
                  }}
                >
                  <FaRegCopy />
                  Copy Article
                </Button>
                <DropdownButton
                  id="dropdown-item-button"
                  title="Download as"
                  style={{
                    borderRadius: "60px",
                  }}
                  size="lg"
                  as={Button}
                >
                  <Dropdown.Item
                    as="button"
                    style={{
                      width: "100%",
                      fontSize: "15px",
                    }}
                    onClick={() => {
                      downloadFile(contentHTML, "html");
                    }}
                  >
                    HTML File
                  </Dropdown.Item>
                  <Dropdown.Item
                    as="button"
                    style={{
                      width: "100%",
                      fontSize: "15px",
                    }}
                    onClick={() => {
                      downloadFile(contentHTML, "txt");
                    }}
                  >
                    Text File
                  </Dropdown.Item>
                  <Dropdown.Item
                    as="button"
                    style={{
                      width: "100%",
                      fontSize: "15px",
                    }}
                    onClick={handleDownload}
                  >
                    Docx File
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            </div>
            <span
              style={{ color: "black", fontSize: "2.3rem", fontWeight: "bold" }}
            >
              Meta Description
            </span>
            <textarea
              id="article"
              className="meta-description"
              value={articleMetaDescription}
              onChange={(e) => setArticleMetaDescription(e.target.value)}
            />
            <Editor
              editorState={value}
              onEditorStateChange={(content) => setValue(content)}
              wrapperClassName="demo-wrapper"
              editorClassName="text-editor-package"
            />
          </div>
          <div
            style={{
              width: "100%",
              minHeight: "200px",
              background: "#ececec",
              borderRadius: "10px",
              marginBottom: "10px",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexDirection: "column",
              gap: "20px",
              padding: "30px 20px",
            }}
          >
            <div
              style={{
                position: "relative",
                display: "inline-block",
              }}
            >
              <h3
                style={{
                  color: "black",
                  fontSize: "1.6rem",
                  fontWeight: "700",
                }}
              >
                Content Score:
              </h3>
              <ReactSpeedometer
                value={0} // this thing will be discussed with Sir and will be doing it later
                maxValue={100}
                forceRender
                height={200}
              />
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "rgba(255,255,234,1)",
                  fontSize: "1.5rem",
                  fontWeight: "700",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  padding: "10px 20px",
                  borderRadius: "10px",
                }}
              >
                Coming Soon
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
                gap: "0.5em",
                color: "black",
              }}
            >
              <div
                style={{
                  borderRadius: "8px",
                  padding: "10px",
                  flex: 1,
                  flexBasis: 0,
                  maxWidth: "calc(100% / 3)",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  border: "1px solid #7F56D9",
                  // backgroundColor: "#7F56D9",
                }}
              >
                <p
                  style={{
                    fontSize: "12px",
                    textAlign: "center",
                    marginBottom: "8px",
                  }}
                >
                  Words
                </p>

                <p
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "12px",
                  }}
                >
                  {numberOfWords}
                </p>
              </div>

              <div
                style={{
                  borderRadius: "8px",
                  padding: "10px",
                  flex: 1,
                  flexBasis: 0,
                  maxWidth: "calc(100% / 3)",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  border: "1px solid #7F56D9",
                }}
              >
                <p
                  style={{
                    fontSize: "12px",
                    textAlign: "center",
                    marginBottom: "8px",
                  }}
                >
                  Headings
                </p>

                <p
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "12px",
                  }}
                >
                  {numberOfHeadings}
                </p>
              </div>
              <div
                style={{
                  borderRadius: "8px",
                  padding: "10px",
                  flex: 1,
                  flexBasis: 0,
                  maxWidth: "calc(100% / 3)",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  border: "1px solid #7F56D9",
                }}
              >
                <p
                  style={{
                    fontSize: "12px",
                    textAlign: "center",
                    marginBottom: "8px",
                  }}
                >
                  Paragraphs
                </p>

                <p
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "12px",
                  }}
                >
                  {numberOfParagraphs}
                </p>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                alignItems: "flex-start",
              }}
            >
              <h3
                style={{
                  color: "black",
                  fontSize: "1.6rem",
                  fontWeight: "700",
                }}
              >
                Target Keywords
              </h3>

              {/* the following div will be used */}
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "0.5em",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  width: "100%",
                  textAlign: "center",
                  padding: "0.5em 0",
                }}
              >
                {withoutStripe ? (
                  <div
                    style={{
                      fontFamily: "Arial, sans-serif",
                      textAlign: "center",
                      marginTop: "50px",
                    }}
                  >
                    <h2
                      style={{
                        color: "#333",
                        fontSize: "24px",
                        marginBottom: "20px",
                      }}
                    >
                      Feature Not Available
                    </h2>
                    <p
                      style={{
                        color: "#666",
                        fontSize: "16px",
                        marginBottom: "20px",
                      }}
                    >
                      We're sorry, but this feature is not available for your
                      current plan.
                      <br />
                      <Link to="/pricing" style={{ textDecoration: "none" }}>
                        <button
                          style={{
                            backgroundColor: "#6941c6",
                            marginTop: "1em",
                            color: "#fff",
                            padding: "10px 20px",
                            borderRadius: "5px",
                            border: "none",
                            cursor: "pointer",
                            fontWeight: "lighter",
                            transition: "background-color 0.3s ease",
                            outline: "none",
                          }}
                        >
                          Upgrade Now
                        </button>
                      </Link>
                    </p>
                  </div>
                ) : (
                  articleById?.extractedKeywords &&
                  articleById?.extractedKeywords?.map(
                    ({ keyword, lowestFrequency, highestFrequency }) => {
                      return (
                        <div
                          key={keyword}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.5rem",
                            padding: "0.5rem 1rem",
                            fontSize: "13px",
                            background: handleBackgroundColor(
                              lowestFrequency,
                              highestFrequency,
                              keywordsOccurrence[keyword]
                            ),
                            color: handleTextColor(
                              lowestFrequency,
                              highestFrequency,
                              keywordsOccurrence[keyword]
                            ),
                            borderRadius: "8px",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                            transition: "transform 0.2s",
                            cursor: "pointer",
                          }}
                          title={`Use ${lowestFrequency} - ${highestFrequency}. Currently used ${keywordsOccurrence[keyword]} times`}
                          onMouseEnter={(e) =>
                            (e.currentTarget.style.transform =
                              "translateY(-2px)")
                          }
                          onMouseLeave={(e) =>
                            (e.currentTarget.style.transform = "translateY(0)")
                          }
                        >
                          <span>{keyword}</span>
                          <span
                            style={{
                              background: "rgba(255, 255, 255, 0.2)",
                              padding: "0.2rem 0.5rem",
                              borderRadius: "4px",
                              marginLeft: "0.5rem",
                              backgroundColor: "white",
                            }}
                          >
                            {keywordsOccurrence[keyword]} / {lowestFrequency} -{" "}
                            {highestFrequency}
                          </span>
                        </div>
                      );
                    }
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="col-md-12">
          <p style={{ color: "black" }}>Sorry couldn't load the document</p>
        </div>
      )}
      {openAddImages && (
        <div className="popup">
          <div className="popup-card">
            <div className="popup-button-wrapper">
              <button
                className="popup-acceptButton"
                onClick={() => setOpenAddImages(false)}
              >
                Close
              </button>
            </div>
            <div className="popup-input-wrapper">
              <input
                placeholder="Search Images By Name"
                name="imageSearch"
                value={imageSearch}
                onChange={(e) => setImageSearch(e.target.value)}
              />
              <div className="button-popup">
                <button
                  className="popup-acceptButton-search"
                  onClick={handleSearchImages}
                >
                  Search
                </button>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "6px",
                  color: "#000",
                  marginLeft: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "2px",
                    padding: "8px 20px",
                    borderRadius: "5px",
                    cursor: "pointer",
                    background:
                      orientation === "portrait" ? "#dcccff" : "white",
                  }}
                  onClick={() => setOrientation("portrait")}
                >
                  <MdCropPortrait
                    style={{
                      fontSize: "2.5rem",
                    }}
                  />
                  <p
                    style={{
                      fontWeight: "normal",
                    }}
                  >
                    Portrait
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "2px",
                    padding: "8px 20px",
                    borderRadius: "5px",
                    cursor: "pointer",
                    background:
                      orientation === "landscape" ? "#dcccff" : "white",
                  }}
                  onClick={() => setOrientation("landscape")}
                >
                  <MdCropLandscape
                    style={{
                      fontSize: "2.5rem",
                    }}
                  />
                  <p
                    style={{
                      fontWeight: "normal",
                    }}
                  >
                    Landscape
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "2px",
                    padding: "8px 20px",
                    borderRadius: "5px",
                    cursor: "pointer",
                    background:
                      orientation === "squarish" ? "#dcccff" : "white",
                  }}
                  onClick={() => setOrientation("squarish")}
                >
                  <MdCropLandscape
                    style={{
                      fontSize: "2.5rem",
                    }}
                  />
                  <p
                    style={{
                      fontWeight: "normal",
                    }}
                  >
                    Square
                  </p>
                </div>
              </div>
            </div>
            {loaderForImages ? (
              <div className="loaderWrapper-popup">
                <DotLoader color="#7F56D9" className="login-clip-loader" />
                <h1
                  style={{
                    color: "#7F56D9",
                    fontSize: "2.4rem",
                  }}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;Loading your images...
                </h1>
              </div>
            ) : (
              <div className="popup-images-container">
                <div className="row" style={{ justifyContent: "center" }}>
                  {imagesData?.length === 0 ? (
                    <div className="heading-wrapper-popup">
                      <h1 style={{ color: "black", height: "100%" }}>
                        Sorry No Data Available for the search.
                      </h1>
                    </div>
                  ) : (
                    imagesData?.map((item) => {
                      return (
                        <div className="col-md-4" key={item?.id}>
                          <div className="popup-image-wrapper">
                            <img
                              className="popup-image"
                              src={item?.urls?.small_s3}
                              alt={item?.alt_description}
                              height={item?.height}
                              width={item?.width}
                            />
                          </div>
                          <div className="popup-image-button">
                            <button
                              className="popup-acceptButton-search"
                              onClick={() =>
                                handleCopyImageUrl(item?.urls?.small_s3)
                              }
                            >
                              Copy URL
                            </button>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
            )}
            <p className="popup-cookieDescription">
              Copy the url and paste it in the editor where you want to use it.{" "}
              <br />
              <span className="popup-span">
                Recommended image dimensions{" "}
                <b>
                  {orientation === "landscape"
                    ? "1920x1080"
                    : orientation === "portrait"
                    ? "1080x1920"
                    : "1080x1080"}
                </b>
                .
              </span>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewArticleFromHistory;
