import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "./checking.scss";
import {
  cancelSubscription,
  renewSubscription,
} from "../../redux/actions/pricing";
import axios from "axios";
import PaidInvoicesTable from "./PaidInvoicesTable";
import BillingInformation from "./BillingInformation";

const SubscriptionInformation = () => {
  const dispatch = useDispatch();
  const user =
    useSelector((state) => state?.Authentication?.user) ||
    localStorage.getItem("User");
  const token =
    useSelector((state) => state?.Authentication?.token) ||
    localStorage.getItem("Token");
  const { _id: userId } = JSON.parse(user);
  const [modalShow, setModalShow] = useState(false);
  const [renewModal, setRenewModal] = useState(false);
  const expiryDetail = useSelector((state) => state?.Pricing?.expiryDetail);
  const status = expiryDetail?.expiryStatus;
  const stripeStatus = expiryDetail?.stripeStatus;
  const plan = expiryDetail?.subscription?.subscriptionPlan;
  const isCanceled = expiryDetail?.subscription?.isCanceled;
  const [paidInvoice,setPaidInvoice] = useState(null);
  const [paymentMethod,setPaymentMethod] = useState(null);


  const withoutStripeHandler = (plan) =>
    [
      "Free Trial",
      "trollyai_tier1",
      "trollyai_tier2",
      "Lifetime Deal",
    ].includes(plan);

  const withoutStripe = withoutStripeHandler(plan);

  let date = new Date(expiryDetail?.subscription?.expiryDate);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let dt = date.getDate();

  if (dt < 10) {
    dt = "0" + dt;
  }
  if (month < 10) {
    month = "0" + month;
  }

  date = year + "-" + month + "-" + dt;

  useEffect(() => {
    if (!withoutStripe && expiryDetail?.subscription?.subscriptionId && expiryDetail?.subscription?.customerId) {
      fetchPaymentMethodAndPaidInvoice();
    }
  }, [withoutStripe, expiryDetail?.subscription?.subscriptionId, expiryDetail?.subscription?.customerId]);

  const fetchPaymentMethodAndPaidInvoice = async () => {
    try {
      const url = `${process.env.REACT_APP_HOST}/stripe/paid-invoices`;
      const payload = {
        subscriptionId: expiryDetail?.subscription?.subscriptionId,
      };
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const invoiceResponse = await axios.post(url, payload, { headers });
      setPaidInvoice(invoiceResponse.data);
      const url2= `${process.env.REACT_APP_HOST}/stripe/payment-method`
      const payload2 = {
        customerId: expiryDetail?.subscription?.customerId
      }
      const paymentResponse = await axios.post(url2,payload2,{headers})

      setPaymentMethod(paymentResponse.data[0]);


      console.log("invoice Response", invoiceResponse);
      console.log("payment method response", paymentResponse)
    } catch (error) {
      console.error("Error fetching payment method:", error);
    }
  };
  const handleCancelPackage = () => {
    dispatch(cancelSubscription(userId));
    setModalShow(false);
  };

  const handleRenewPackage = () => {
    const subscriptionId = expiryDetail?.subscription?.subscriptionId;
    const payload = { subscriptionId };
    dispatch(renewSubscription(payload, userId));
    setRenewModal(false);
  };

  return (
    <div style={{color: "black", padding: "1em"}}>
      <h1 className="heading">Your Subscription Information</h1>
      <div className="subscription-cards" >
      <div style={{ padding: '20px', border: '1px solid #ccc', borderRadius: '5px', background: '#f9f9f9' }}>
  <p>
    <span style={{ fontWeight: 'bold' }}>Plan Name:</span> {plan}
  </p>
  <p>
    <span style={{ fontWeight: 'bold' }}>
      {withoutStripe ? `Expiration Date:` : `Next Billing Date:`}
    </span>{" "}
    {date}
  </p>
  <p>
    <span style={{ fontWeight: 'bold' }}>Status:</span>{" "}
    {withoutStripe ? status : stripeStatus}
  </p>

  {(status === "expired") && (
    <Link to="/pricing" style={{ display: 'block', width: '100%', fontWeight: "bold", padding: '10px', background: '#641cf2', color: '#fff', textAlign: 'center', textDecoration: 'none', borderRadius: '5px', margin: '10px 0' }}>
      Buy the subscription
    </Link>
  )}

  {!withoutStripe && (
    <div>
      <button
        onClick={() => setModalShow(true)}
        disabled={isCanceled}
        style={{ padding: '10px', background: '#bb2124', color: '#fff', borderRadius: '5px', border: 'none', cursor: 'pointer', marginRight: '10px', opacity: isCanceled ? 0.5 : 1 }}
      >
        {isCanceled ? "Subscription Canceled" : "Cancel Subscription"}
      </button>
      <button
        onClick={() => setRenewModal(true)}
        disabled={!isCanceled}
        style={{ padding: '10px', background: '#22bb22', color: '#fff', borderRadius: '5px', border: 'none', cursor: 'pointer', opacity: !isCanceled ? 0.5 : 1 }}
      >
        {isCanceled ? "Renew Subscription" : "Subscription Active"}
      </button>
    </div>
  )}
</div>

      <div>
        {!withoutStripe && <BillingInformation paymentMethod={paymentMethod} />}
      </div>
      </div>
     


      {!withoutStripe && paidInvoice && <PaidInvoicesTable paidInvoices={paidInvoice} />}
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          closeButton
          style={{ borderBottom: "none", textAlign: "center" }}
        >
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{
              fontSize: "1.8em",
              fontWeight: "700",
              color: "#af4261",
              width: "100%",
            }}
          >
            Confirm Cancellation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "1.5em", textAlign: "center" }}>
          <h4
            style={{
              color: "#333",
              fontWeight: "600",
              marginBottom: "1em",
              fontSize: "1.2em",
            }}
          >
            Are you sure you want to cancel?
          </h4>
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "center",
            borderTop: "none",
          }}
        >
          <Button
            style={{
              backgroundColor: "#e74c3c",
              borderColor: "#e74c3c",
              borderRadius: "8px",
              padding: "0.5em 1.5em",
              fontSize: "1em",
              fontWeight: "500",
              marginRight: "1em",
              transition: "background-color 0.3s ease, color 0.3s ease",
            }}
            onMouseOver={(e) => (e.target.style.backgroundColor = "#c0392b")}
            onMouseOut={(e) => (e.target.style.backgroundColor = "#e74c3c")}
            onClick={handleCancelPackage}
          >
            Yes
          </Button>
          <Button
            style={{
              backgroundColor: "#95a5a6",
              borderColor: "#95a5a6",
              borderRadius: "8px",
              padding: "0.5em 1.5em",
              fontSize: "1em",
              fontWeight: "500",
              transition: "background-color 0.3s ease, color 0.3s ease",
            }}
            onMouseOver={(e) => (e.target.style.backgroundColor = "#7f8c8d")}
            onMouseOut={(e) => (e.target.style.backgroundColor = "#95a5a6")}
            onClick={() => setModalShow(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={renewModal}
        onHide={() => setRenewModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          closeButton
          style={{ borderBottom: "none", textAlign: "center" }}
        >
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{
              fontSize: "1.8em",
              fontWeight: "700",
              color: "#27ae60",
              width: "100%",
            }}
          >
            Confirm Renewal
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "1.5em", textAlign: "center" }}>
          <h4
            style={{
              color: "#333",
              fontWeight: "600",
              marginBottom: "1em",
              fontSize: "1.2em",
            }}
          >
            Are you sure you want to renew subscription?
          </h4>
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "center",
            borderTop: "none",
          }}
        >
          <Button
            style={{
              backgroundColor: "#27ae60",
              borderColor: "#27ae60",
              borderRadius: "8px",
              padding: "0.5em 1.5em",
              fontSize: "1em",
              fontWeight: "500",
              marginRight: "1em",
              transition: "background-color 0.3s ease, color 0.3s ease",
            }}
            onMouseOver={(e) => (e.target.style.backgroundColor = "#219150")}
            onMouseOut={(e) => (e.target.style.backgroundColor = "#27ae60")}
            onClick={handleRenewPackage}
          >
            Yes
          </Button>
          <Button
            style={{
              backgroundColor: "#95a5a6",
              borderColor: "#95a5a6",
              borderRadius: "8px",
              padding: "0.5em 1.5em",
              fontSize: "1em",
              fontWeight: "500",
              transition: "background-color 0.3s ease, color 0.3s ease",
            }}
            onMouseOver={(e) => (e.target.style.backgroundColor = "#7f8c8d")}
            onMouseOut={(e) => (e.target.style.backgroundColor = "#95a5a6")}
            onClick={() => setRenewModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SubscriptionInformation;
